import { Link } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { useInView } from "react-intersection-observer"
// import "../../styles/component/platfrom-v1.scss"
import LazyLoad from "react-lazyload"
import Fade from "react-reveal/Fade"
import Slide from "react-reveal/Slide"
import EsArrowDown from "../../assets/images/resources/es-arrow-down.png"
import ErrorIcon from "../../assets/images/signup-error.svg"
import Pencil from "../../assets/images/signup/pencil-emoji.svg"
import Buttons from "../../components/common/button"
import CTA from "../../components/common/CTA"
import CTABoxLp from "../../components/common/cta-box-lp"
import FooterLP from "../../components/common/footer-lp"
import Frame from "../../components/common/frame"
import IntegrationCards from "../../components/common/integration-tools-new"
import LandingPagePlatform from "../../components/common/LandingPagePlatform"
import PopupSignup from "../../components/common/popup-sign-up"
import SignupForm from "../../components/common/sign-up-form"
import SVGIcon from "../../components/common/SVGIcon"
import Testimonial from "../../components/common/testimonial-component/testimonial"
import UspInteractiveNew from "../../components/common/usp-interactive-new"
import FeaturesList from "../../components/landing-pages/features-list"
import Navigation from "../../components/navigation"
import PatternIconLarge from "../../components/utility/pattern_icons_large"
import { serverRegions } from "../../constants/globalMessages"
import "../../styles/component/USP-section.scss"
import "../../styles/pages/landing-page-three.scss"
import "../../styles/pages/landing-pages.scss"
import "./../../../src/styles/component/platform-v1.scss"
import InteractiveTabs from "./../../components/landing-pages/interactive-tabs"
import Typer from "./../../components/utility/typewriter"

function TemplateOneNew({ pageContext }) {
  const { template, links } = pageContext

  const [showTestimonial, setShowTestimonial] = useState(false)
  const [showBg, setShowBg] = useState(false)
  const [popupVisibility, setPopupVisibility] = useState(false)

  const [refTestimonial, inViewTestimonial] = useInView({
    triggerOnce: true,
    threshold: 0,
  })

  const [refFeatures, inViewFeatures] = useInView({
    triggerOnce: false,
    threshold: 0,
  })

  const [serverCountry, setServerCountry] = useState("US")
  const [countryPopup, setCountryPopup] = useState(false)
  const wrapperRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setCountryPopup(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }

  useEffect(() => {
    if (showTestimonial === false && inViewTestimonial === true) {
      setShowTestimonial(true)
    }

    if (inViewFeatures === true) {
      setShowBg(true)
    } else {
      setShowBg(false)
    }
  }, [inViewTestimonial, showTestimonial, inViewFeatures, setShowBg])

  const [activeTab, setActiveTab] = useState(0)

  const [currentNavValue, setCurrentNavValue] = useState("")

  useEffect(() => {
    const featParDetail = template.components.filter(
      el => el.__typename === "SuperOps_FeatureDetailComponent"
    )

    if (featParDetail.length > 0) {
      setCurrentNavValue(featParDetail[0]?.featureParentDetails[0]?.title)
    }
  }, [])

  const setActiveAndCurrentTab = (value, title) => {
    activeCurrentTab(value, title)
  }

  const setActiveAndCurrentTabKD = (value, title) => {
    activeCurrentTab(value, title)
  }

  const activeCurrentTab = (value, title) => {
    setActiveTab(value)
    setCurrentNavValue(title)
  }

  const [refUSP, inViewUSP] = useInView({
    triggerOnce: true,
    threshold: 0,
  })

  useEffect(() => {
    setInnerWidth(document.body.clientWidth)
  })

  const [showUSP, setShowUSP] = useState(false)
  const [innerWidth, setInnerWidth] = useState(0)

  const [displayError, setDisplayError] = useState(false)
  const [errorText, setErrorText] = useState("")

  return (
    <>
      <Frame
        seo={template.seo}
        IsFooterVisble="No"
        extraTags={
          template.slug === "lp/msp-software"
            ? [<meta name="robots" content="noindex" />]
            : []
        }
      >
        <div>
          <PopupSignup
            visibility={popupVisibility}
            togglePopup={togglePopup}
            ctaText={template.signupFormCtaText}
            title={template.signupFormTitle}
            disallowCommonDomains
          />
        </div>

        <div
          className={`${
            template.landingPageTemplate === "Three"
              ? "lp-temp-three"
              : "landing-page"
          }`}
        >
          <Navigation
            page="Landing page"
            navClassName="navigation-lp"
            color={template.navigationBackgroundColor}
            ctaText={template.navigationCtaText}
            formOneAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
            formTwoAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
            signupPopup
            disallowCommonDomains
          />
          <div
            className={`${
              template.landingPageTemplate !== "One" ? "main" : ""
            }`}
          >
            {template.components.map(field => {
              switch (field.__typename) {
                case "SuperOps_Hero": {
                  return (
                    <>
                      {template.landingPageTemplate === "Three" ? (
                        <>
                          <div
                            className="hero"
                            style={{
                              background: field.backgroundColor,
                              marginBottom:
                                template.components.filter(
                                  el => el.__typename === "SuperOps_Testimonial"
                                ).length > 0
                                  ? "-160px"
                                  : "0px",
                            }}
                          >
                            <Container>
                              <Row className="justify-content-between">
                                <Col lg={5}>
                                  <span className="p14">
                                    <p className="breadcrumb">
                                      {field.heroExcerpt}
                                    </p>
                                  </span>
                                  <Fade>
                                    {field.heroAnimType ? (
                                      <>
                                        {field.heroAnimType === "strike" ? (
                                          <div
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            <h1>
                                              {field.heroHeadingLine1}
                                              <span>
                                                <Typer
                                                  sentences={
                                                    field.heroAnimAltText
                                                  }
                                                  loop={false}
                                                  startDelay={500}
                                                  typingSpeed={90}
                                                  deletingSpeed={70}
                                                  cursorClassName={"animCursor"}
                                                  hideCursorOnFinish={true}
                                                  cursorSmooth={true}
                                                ></Typer>
                                              </span>
                                            </h1>
                                            <h1>{field.heroHeadingLine2}</h1>
                                          </div>
                                        ) : (
                                          <div>
                                            <h1 className="position-relative">
                                              {field.heroHeadingLine1}
                                              <span className="underline">
                                                {field.heroAnimAltText}
                                              </span>
                                              {field.heroHeadingLine2}
                                            </h1>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <h1>{field.heroheading.text}</h1>
                                    )}
                                  </Fade>
                                  <div className="p24">
                                    <Fade>
                                      <p className="excerpt">
                                        {field.heroExcerptList.map(excerpt => (
                                          <span className="position-relative">
                                            {excerpt}
                                          </span>
                                        ))}
                                      </p>
                                    </Fade>
                                  </div>
                                  <Fade>
                                    <Buttons
                                      theme="primary mt-4"
                                      arrow
                                      onClick={() => togglePopup()}
                                      text={field.heroCtaText}
                                    />
                                    <span className="p12 subtext">
                                      <p className="mt-3">
                                        no credit card required.
                                      </p>
                                    </span>
                                  </Fade>
                                </Col>
                                <Col
                                  className="align right position-relative"
                                  lg={6}
                                >
                                  <Fade>
                                    <img
                                      src={field.heroImage.url}
                                      alt="hero"
                                      className="position-absolute"
                                    />
                                  </Fade>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="hero"
                            style={{
                              background: field.backgroundColor,
                              marginBottom:
                                template.components.filter(
                                  el =>
                                    el.__typename ===
                                    "SuperOps_BrowserTabComponent"
                                ).length > 0
                                  ? "-200px"
                                  : "0px",
                            }}
                          >
                            <Container>
                              {template.landingPageTemplate === "One" ? (
                                <Row>
                                  <Col lg={6}>
                                    <Fade>
                                      {field.animType ? (
                                        <>
                                          {field.animType === "strike" ? (
                                            <div
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <h1>
                                                {field.headingLine1}
                                                <span>
                                                  <Typer
                                                    sentences={
                                                      field.animAltText
                                                    }
                                                    loop={false}
                                                    startDelay={500}
                                                    typingSpeed={90}
                                                    deletingSpeed={70}
                                                    cursorClassName={
                                                      "animCursor"
                                                    }
                                                    hideCursorOnFinish={true}
                                                    cursorSmooth={true}
                                                  ></Typer>
                                                </span>
                                              </h1>
                                              <h1>{field.headingLine2}</h1>
                                            </div>
                                          ) : (
                                            <div>
                                              <h1 className="position-relative">
                                                {field.headingLine1}
                                                <span className="underline">
                                                  {field.animAltText}
                                                </span>
                                                {field.headingLine2}
                                              </h1>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <h1>{parse(field.heroheading.text)}</h1>
                                      )}
                                    </Fade>
                                    <div className="p18">
                                      <Fade>
                                        <p>{field.heroExcerpt}</p>
                                      </Fade>
                                    </div>
                                    {field.heroExcerptList &&
                                    field.heroExcerptList.length ? (
                                      <div className="benefit-parent d-flex ">
                                        {field.heroExcerptList.map(el => {
                                          return (
                                            <div className="benefits d-flex align-items-center">
                                              <SVGIcon
                                                name="greenTick"
                                                className="tick-logo"
                                              />
                                              <div className="p14">
                                                <p className="name m-0">{el}</p>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    ) : null}
                                    <Fade>
                                      <Buttons
                                        theme="primary mt-3"
                                        arrow
                                        onClick={() => togglePopup()}
                                        text={field.heroCtaText}
                                      />
                                    </Fade>
                                  </Col>
                                  <Col lg={6} className="align">
                                    <Fade>
                                      <img
                                        src={field.heroImage.url}
                                        alt="hero"
                                        width="100"
                                        height="100"
                                        className="position-relative"
                                      />
                                    </Fade>
                                  </Col>
                                </Row>
                              ) : (
                                <>
                                  <div className="alt-hero">
                                    <Fade>
                                      {field.animType ? (
                                        <>
                                          {field.animType === "strike" ? (
                                            <div
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <h1>
                                                {field.headingLine1}
                                                <span>
                                                  <Typer
                                                    sentences={
                                                      field.animAltText
                                                    }
                                                    loop={false}
                                                    startDelay={500}
                                                    typingSpeed={90}
                                                    deletingSpeed={70}
                                                    cursorClassName={
                                                      "animCursor"
                                                    }
                                                    hideCursorOnFinish={true}
                                                    cursorSmooth={true}
                                                  ></Typer>
                                                </span>
                                              </h1>
                                              <h1>{field.headingLine2}</h1>
                                            </div>
                                          ) : (
                                            <div>
                                              <h1 className="position-relative">
                                                {field.headingLine1}
                                                <span className="underline">
                                                  {field.animAltText}
                                                </span>
                                                {field.headingLine2}
                                              </h1>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <h1>{parse(field.heroheading.text)}</h1>
                                      )}
                                    </Fade>

                                    <Fade>
                                      <div className="compare">
                                        <div className="logo">
                                          <img
                                            src="https://media.graphcms.com/HkHLzxaT026489ePx8TD"
                                            alt="logo"
                                            height="10"
                                            width="10"
                                          />
                                        </div>
                                        <div className="line position-relative">
                                          <div className="vs position-relative">
                                            <span className="p14">
                                              <p className="position-relative">
                                                {" "}
                                                VS{" "}
                                              </p>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="logo">
                                          <img
                                            src={field.heroImage.url}
                                            alt="logo"
                                            height="10"
                                            width="10"
                                          />
                                        </div>
                                      </div>
                                    </Fade>

                                    <div className="p18 t2-he">
                                      <Fade>
                                        <p>{field.heroExcerpt}</p>
                                      </Fade>
                                    </div>

                                    <Fade>
                                      <Buttons
                                        theme="primary"
                                        arrow
                                        onClick={() => togglePopup()}
                                        text={field.heroCtaText}
                                      />
                                    </Fade>
                                  </div>
                                </>
                              )}
                            </Container>
                          </div>
                        </>
                      )}
                    </>
                  )
                }
                case "SuperOps_Testimonial": {
                  return (
                    <>
                      {field.testimonialType === "single_2" ? (
                        <Testimonial
                          type="single-2"
                          marginTop="80px"
                          marginBottom="40px"
                          testimonial={field.testimonialDescription[0]}
                          guestName={field.testimonialName[0]}
                          guestTitle={field.testimonialTitle[0]}
                        />
                      ) : (
                        <div
                          style={{
                            display:
                              field.testimonialType === "multiple_1"
                                ? "block"
                                : "none",
                          }}
                        >
                          <div ref={refTestimonial}>
                            <Testimonial
                              type="multiple-1"
                              heading={field.testimonialSectionHeading}
                              mainBg={field.testimonialMainBackgroundColor}
                              cardBg={field.testimonialCardBackgroundColor}
                              testimonial={field.testimonialDescription}
                              name={field.testimonialName}
                              title={field.testimonialTitle}
                              image={field.testimonialImage}
                              showTestimonial={showTestimonial}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )
                }
                case "SuperOps_BrowserTabComponent": {
                  return (
                    <>
                      <div className="tabs mt80">
                        <Fade>
                          <Container
                            style={{
                              boxShadow:
                                "0px -20px 40px -24px rgb(0 0 0 / 10%)",
                            }}
                          >
                            <h3> {field.tabsHeading} </h3>

                            <InteractiveTabs
                              title={field.tabsTitle}
                              image={field.tabsImage}
                              ctaText={field.tabsCtaText}
                              togglePopup={togglePopup}
                            />
                          </Container>
                        </Fade>
                      </div>
                    </>
                  )
                }
                case "SuperOps_BannerComponent": {
                  return (
                    <>
                      {field.bannerType === "small" ? (
                        <>
                          <div className="light-box light-box-lp">
                            <Slide
                              style={{
                                "--sal-delay": "0.2s",
                              }}
                            >
                              <CTABoxLp
                                style={{ padding: "0px 200px" }}
                                theme="light"
                                heading={field.bannerHeading}
                              />
                            </Slide>
                          </div>
                        </>
                      ) : (
                        <section
                          style={{
                            marginBottom:
                              template.components.filter(
                                el =>
                                  el.__typename ===
                                    "SuperOps_BannerComponent" &&
                                  el.bannerType === "small"
                              ).length > 0
                                ? "-82px"
                                : "0px",
                          }}
                        >
                          <Slide delay="200">
                            <Container
                              className="mx-auto banner-main"
                              style={{
                                background: field.bannerBgColor,
                                paddingBottom:
                                  template.components.filter(
                                    el =>
                                      el.__typename ===
                                        "SuperOps_BannerComponent" &&
                                      el.bannerType === "small"
                                  ).length > 0
                                    ? "50px"
                                    : "0px",
                                borderRadius: "10px",
                              }}
                            >
                              <Row className="lp-banner">
                                <Col lg={6} className="lp-banner-left">
                                  <>
                                    {field.bannerType === "Big" ? (
                                      <div className="lp-banner-des-cms ">
                                        {parse(field.bannerDescriptionBig.html)}
                                      </div>
                                    ) : (
                                      <>
                                        <div className="fe-gl-details prelative ">
                                          <h2 className="heading">
                                            {field.bannerHeading}
                                          </h2>
                                        </div>

                                        <div className="lp-banner-p ">
                                          <p>{field.bannerDescription}</p>
                                        </div>
                                      </>
                                    )}
                                  </>
                                </Col>

                                <Col lg={6} className="lp-banner-right">
                                  <div className="fe-hero-cover">
                                    <img
                                      src={field.bannerImage.url}
                                      alt="img"
                                      className="w-100"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </Slide>
                        </section>
                      )}
                    </>
                  )
                }
                case "SuperOps_Block": {
                  return (
                    <>
                      <section className="lp-block mx-auto">
                        <Slide delay="200">
                          <center>
                            <Container>
                              <Row>
                                <Col lg={6} className="block-left">
                                  <h2 className="heading">
                                    {field.blockHeading}
                                  </h2>
                                  <p>{field.blockContent.text}</p>
                                </Col>

                                <Col lg={6} className="block-right">
                                  <img
                                    className="block-img"
                                    src={field.blockImage[0].url}
                                    alt="img"
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </center>
                        </Slide>
                      </section>
                    </>
                  )
                }
                case "SuperOps_MainBlock": {
                  return (
                    <>
                      {field.isDecoration === "Yes" ? (
                        <section>
                          <Slide delay="200">
                            <center>
                              <Container>
                                <Row>
                                  <Col
                                    lg={10}
                                    className="mx-auto position-relative "
                                  >
                                    <div className="lp-anim ">
                                      <PatternIconLarge />
                                    </div>
                                    <div className="card-block-lp ">
                                      <h2>{field.heading}</h2>
                                      <p>{parse(field.content.html)}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </center>
                          </Slide>
                        </section>
                      ) : (
                        <section>
                          <Slide delay="200">
                            <center>
                              <Container>
                                <Row>
                                  <Col lg={8} className="mx-auto">
                                    <div className="main-block-lp">
                                      <h2>{field.heading}</h2>
                                      <p>{parse(field.content.html)}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </center>
                          </Slide>
                        </section>
                      )}
                    </>
                  )
                }
                case "SuperOps_Advantage": {
                  return (
                    <>
                      <center>
                        <div className="fe-outer-mlr adv-box">
                          <section className="fe-benefits-outer fe-adv-outer lp-adv">
                            <Slide delay="200">
                              <div className="fe-benefits-inner">
                                <Row>
                                  {field.advantagesHeading.map(
                                    (heading, index) => {
                                      return (
                                        <Col md={4}>
                                          <div className="fe-benefits-wrap">
                                            <div className="heading-wrapper">
                                              <img
                                                src={
                                                  field.advantagesIcon[index]
                                                    .url
                                                }
                                                alt="img"
                                              />
                                              <h5>{heading}</h5>
                                            </div>
                                            <div>
                                              <p className="content">
                                                {field.advantagesExcerpt[index]}
                                              </p>
                                            </div>
                                          </div>
                                        </Col>
                                      )
                                    }
                                  )}
                                </Row>
                              </div>
                            </Slide>
                          </section>
                        </div>
                      </center>
                    </>
                  )
                }
                case "SuperOps_FeaturesListComponent": {
                  return (
                    <>
                      <div className="support">
                        <div>
                          <Container>
                            <Fade>
                              <h3>{field.featuresHeading}</h3>
                            </Fade>
                            <div ref={refFeatures}>
                              <FeaturesList
                                icons={field.featuresIcon}
                                content={field.featuresDescription}
                              />
                            </div>
                            <Fade>
                              <Buttons
                                theme="primary"
                                arrow
                                onClick={togglePopup}
                                text={field.featuresCtaText}
                              />
                            </Fade>
                          </Container>
                        </div>
                      </div>
                    </>
                  )
                }
                case "SuperOps_FeatureDetailComponent": {
                  return (
                    <>
                      <section className="fe-gl-content-outer fe-pr-content-outer">
                        <center>
                          <section className="fe-gl-content-inner">
                            <section
                              className="es-nav-wrap fe-gl-nav-mob d-mob"
                              id="navContent"
                            >
                              <Container>
                                <center>
                                  <div className="outer">
                                    <div className="select">
                                      <Dropdown id="drp-main">
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="drp-btn"
                                        >
                                          {currentNavValue}
                                          <img
                                            src={EsArrowDown}
                                            height="1"
                                            width="1"
                                            alt="img"
                                          />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu id="drp-menu">
                                          {field.featureParentDetails.map(
                                            (fpd, id) => {
                                              return (
                                                <Dropdown.Item>
                                                  <div
                                                    className="ch-title"
                                                    onClick={() =>
                                                      setActiveAndCurrentTab(
                                                        id,
                                                        fpd.title
                                                      )
                                                    }
                                                    onKeyDown={() =>
                                                      setActiveAndCurrentTabKD(
                                                        id,
                                                        fpd.title
                                                      )
                                                    }
                                                    role="button"
                                                    tabIndex={0}
                                                  >
                                                    <p>{fpd.title}</p>
                                                  </div>
                                                </Dropdown.Item>
                                              )
                                            }
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </center>
                              </Container>
                            </section>

                            <Row className="fe-gl-content">
                              <Col lg={3}>
                                <Slide delay="200">
                                  <div className="fpd-wrap fe-gl-nav-dsk d-dsk">
                                    {field.featureParentDetails.map(
                                      (fpd, id) => {
                                        return (
                                          <div
                                            className={`fgl-nav ${id ===
                                              activeTab && "active"}`}
                                            onClick={() => setActiveTab(id)}
                                            onKeyDown={() => setActiveTab(id)}
                                            role="button"
                                            tabIndex={0}
                                          >
                                            <p>{fpd.title}</p>

                                            <div className="at-line" />
                                          </div>
                                        )
                                      }
                                    )}
                                  </div>
                                </Slide>
                              </Col>
                              <Col lg={9} className="fe-gl-pr">
                                <Fade>
                                  {field.featureParentDetails.map((fd, i) => {
                                    return (
                                      <div
                                        className="fe-hide"
                                        id={i}
                                        style={{
                                          display:
                                            activeTab === i ? "block" : "none",
                                        }}
                                      >
                                        <div className="fe-gl-details prelative">
                                          <h2>{fd.heading}</h2>

                                          <p> {fd.description} </p>
                                        </div>

                                        {!!fd.image ? (
                                          <Row>
                                            <Col lg={12}>
                                              <div className="img-container fd-img-container">
                                                <img
                                                  src={fd.image.url}
                                                  className="fd-img"
                                                  alt="img"
                                                />
                                              </div>
                                            </Col>
                                            {/* <Col lg={4}>
                                          {fd.optionsHeading.map((t, titleIndex) => {
                                            return (
                                              <div>
                                                <div className="fe-gl-child">
                                                  <p className="title">
                                                    {" "}
                                                    <img
                                                      src={
                                                        fd.optionsIcon[titleIndex].url
                                                      }
                                                      alt="img"
                                                    />{" "}
                                                    {t}{" "}
                                                  </p>
      
                                                  <p className="details">
                                                    {" "}
                                                    {
                                                      fd.optionsDescription[
                                                        titleIndex
                                                      ]
                                                    }{" "}
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </Col> */}
                                          </Row>
                                        ) : (
                                          <div className="column-container">
                                            {fd.optionsHeading.map(
                                              (tl, titleIndex) => {
                                                return (
                                                  <div className="column-2 column-m-2 column-s-1">
                                                    <div className="fe-gl-child">
                                                      <p className="title">
                                                        {" "}
                                                        <img
                                                          src={
                                                            fd.optionsIcon[
                                                              titleIndex
                                                            ].url
                                                          }
                                                          alt="img"
                                                        />{" "}
                                                        {tl}{" "}
                                                      </p>

                                                      <p className="details">
                                                        {" "}
                                                        {
                                                          fd.optionsDescription[
                                                            titleIndex
                                                          ]
                                                        }{" "}
                                                      </p>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            )}
                                          </div>
                                        )}

                                        <div
                                          className="fe-pr-link"
                                          style={{ background: fd.linkBg }}
                                        >
                                          <a href={fd.linkUrl}>
                                            <p> {fd.linkText} </p>
                                          </a>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </Fade>
                              </Col>
                            </Row>
                          </section>
                        </center>
                      </section>
                    </>
                  )
                }
                case "SuperOps_UspComponent": {
                  return (
                    <>
                      <section className="usp-section sops-w-95" ref={refUSP}>
                        <Slide style={{ "--sal-delay": "0.2s" }}>
                          <Container>
                            <UspInteractiveNew
                              heading={parse(field.uspHeading.html)}
                              page="home"
                              displayWidth={innerWidth}
                              showUSP={showUSP}
                              // items={data.SuperOps.peopleList.filter(
                              //   el => el.page === "Home Better"
                              // )}
                              items={field.peopleList}
                            />
                          </Container>
                        </Slide>
                      </section>
                    </>
                  )
                }
                case "SuperOps_IntegrationComponent": {
                  return (
                    <>
                      <Slide style={{ "--sal-delay": "0.2s" }}>
                        <section className="integrations-section">
                          <Container>
                            <div className="offset-background d-dsk"> </div>
                            <Row>
                              <Col lg={5} className="left">
                                <LazyLoad>
                                  <div className="position-relative">
                                    <div className="breadcrumb p14">
                                      <p>marketplace</p>
                                    </div>
                                    <div className="heading position-relative mb-4">
                                      {parse(field.integrationHeading.html)}
                                    </div>
                                  </div>
                                  <Buttons
                                    theme="secondary"
                                    link={"/marketplace"}
                                    text="VIEW THEM ALL"
                                    arrow
                                  />
                                </LazyLoad>
                              </Col>
                              <Col lg={7} className="right">
                                <Slide style={{ "--sal-delay": "0.2s" }}>
                                  <IntegrationCards data={field.seoPosts} />
                                </Slide>
                              </Col>
                            </Row>
                          </Container>
                        </section>
                      </Slide>
                    </>
                  )
                }
                case "SuperOps_LpSignUpComponent": {
                  return (
                    <>
                      <section className="signup-box-section">
                        <Container>
                          <div className="signup-box dc-signup">
                            <Row>
                              <Col lg={6}>
                                <div className="left-content altv3 h2 p16">
                                  {parse(field.content.html)}
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="right">
                                  <section className="cn-cta bt-cta bts-cta">
                                    <Container>
                                      <div
                                        className="sign-api-error"
                                        style={{
                                          display: displayError
                                            ? "block"
                                            : "none",
                                        }}
                                      >
                                        <img
                                          src={ErrorIcon}
                                          height="10"
                                          width="10"
                                          className="signup-error-icon"
                                          alt="img"
                                        />
                                        <span className="p14">
                                          <p>{errorText}</p>
                                        </span>
                                      </div>
                                      <SignupForm
                                        formAPI={
                                          process.env
                                            .HUBSPOT_BETA_SIGNUP_API_ENDPOINT
                                        }
                                        DisplayErrorSetter={val =>
                                          setDisplayError(val)
                                        }
                                        ErrorTextSetter={val =>
                                          setErrorText(val)
                                        }
                                        serverCountry={serverCountry}
                                        setServerCountry={val =>
                                          setServerCountry(val)
                                        }
                                        disallowCommonDomains
                                        signupEU
                                      />
                                      <span className="action">
                                        <center>
                                          <p className="data-loc mt16">
                                            Your data will be located in the{" "}
                                            <span
                                              ref={wrapperRef}
                                              className="fw-bold text-deep-purple position-relative country-name"
                                              onClick={() =>
                                                setCountryPopup(!countryPopup)
                                              }
                                            >
                                              <div
                                                className={`position-absolute pop-up-location br-10 z-1 ${
                                                  countryPopup ? "" : "dspnone"
                                                }`}
                                              >
                                                {Object.values(
                                                  serverRegions
                                                ).map((item, id) => {
                                                  return (
                                                    <p
                                                      className={`text fw-bold p14 ${
                                                        !serverRegions[
                                                          serverCountry
                                                        ] && item.code === "US"
                                                          ? "text-pink"
                                                          : item.code ===
                                                            serverCountry
                                                          ? "text-pink"
                                                          : ""
                                                      }`}
                                                      onClick={() =>
                                                        setServerCountry(
                                                          item.code
                                                        )
                                                      }
                                                    >
                                                      {item.country}
                                                    </p>
                                                  )
                                                })}
                                                <div className="position-relative">
                                                  <SVGIcon
                                                    name="polygon"
                                                    className="polygon position-absolute"
                                                  />
                                                </div>
                                              </div>
                                              {serverRegions[serverCountry]
                                                ?.country ||
                                                serverRegions["US"].country}
                                            </span>
                                          </p>
                                          <p>
                                            By clicking "GET STARTED FOR FREE",
                                            you agree to SuperOps's{" "}
                                            <Link to="/terms">
                                              Terms of use
                                            </Link>{" "}
                                            and{" "}
                                            <Link to="/privacy">
                                              Privacy policy
                                            </Link>
                                            .
                                          </p>
                                        </center>
                                      </span>
                                      <span className="info-bottom">
                                        <img src={Pencil} alt="note" />
                                        <span className="p14">
                                          <p>
                                            P.S. We won’t ask for you for your
                                            credit card details until you’re
                                            absolutely ready.
                                          </p>
                                        </span>
                                      </span>
                                    </Container>
                                  </section>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Container>
                      </section>
                    </>
                  )
                }
                case "SuperOps_PlatformComponent": {
                  return (
                    <>
                      <div>
                        <LandingPagePlatform
                          heading={field.platformHeading}
                          description={field.platformDescription}
                          //   image={field.platformImage.url}
                          showLinks="No"
                        />
                      </div>
                    </>
                  )
                }
                case "SuperOps_CtaFeature": {
                  return (
                    <>
                      <div className="mt80">
                        <CTA
                          data={[field.ctaBox]}
                          onClick={togglePopup}
                          formOneAPI={
                            process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT
                          }
                          formTwoAPI={
                            process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT
                          }
                          disallowCommonDomains
                        />
                      </div>
                    </>
                  )
                }
              }
            })}
            <FooterLP items={links[0]} />
          </div>
        </div>
      </Frame>
    </>
  )
}

export default TemplateOneNew
